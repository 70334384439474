.transitioning-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  opacity: 0.8; /* Keep it slightly transparent */
  animation: slideAcross 5s linear infinite; /* Adjust duration as needed */
  z-index: 10; /* Ensure it's above other elements */
}

@keyframes slideAcross {
  0% {
    left: -100%; /* Start off-screen from the left */
  }
  50% {
    left: 0; /* Centered over the video */
  }
  100% {
    left: 100%; /* Move off-screen to the right */
  }
}

/* Fullscreen adjustments */
:fullscreen .transitioning-div {
  z-index: 10;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 20px; /* Space between cards */
  padding: 20px;
}

.card {
  background-color: #f4f4f4;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.card-content {
  padding: 15px;
}

.card-content h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #333;
}

.card-content p {
  margin: 0;
  color: #777;
  font-size: 14px;
}

.selected {
  border: 3px solid green;
}

/* CustomPlayer.css */

.player-container {
  position: relative;
  // width: 100%;
  // height: 100%;
}

// .fullscreen {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background: black;
//   z-index: 9999;
// }

// .fullscreen .react-player {
//   width: 100%;
//   height: 100%;
// }

.fullscreen .fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10000;
}

.fullscreen > :first-child {
  height: 100% !important;
  width: 100% !important;
}

#fullscreen-control-bar-button {
  display: none !important;
}

.fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.transition-css {
  width: 80px;
  font-size: 10px;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: top 20s ease-in-out 0s, left 20s ease-in-out 0s;
  font-weight: bold;
}

.lecture-text {
  text-align: center;
  font-weight: bold;
}
