.vc {
  background: #f9ae00;
  color: black;
  width: auto;
  text-align: center;
  padding: 6px 18px;
  border-top-left-radius: 5px;
  position: absolute;
  z-index: 2;
}
.vc:after {
  width: 0;
  height: 0;
  border: 16.5px solid transparent;
  position: absolute;
  content: "";
  border-top-color: #f9ae00;
  right: -23px;
  top: 0px;
  border-left-color: #f9ae00;
}
.vc:before {
  width: 0;
  height: 0;
  border: 16px solid transparent;
  position: absolute;
  content: "";
  border-bottom-color: #f9ae00;
  right: -23px;
  top: -1px;
}

.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}

.no-margin-right {
  margin-right: 0 !important;
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}
