.wrapperClassName {
  min-height: 280px;
}

.editorClassName {
  z-index: 1;
  min-height: 200px;
  max-height: 200px;
  margin-bottom: 25px;
  background: white;
  border: 1px solid gray;
  padding: 10px;
}
