.calc-modal {
  .ant-radio-button-wrapper-checked {
    border-right-color: #f9ae00;
    background: #f9ae00 !important;
    border-color: #f9ae00 !important;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #f9ae00;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: #f9ae00 !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #f9ae00 !important;
  }
}

.danger-risk,
.danger-risk:hover,
.danger-risk:focus {
  border: 1px solid red !important;
}
