@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
    url("../../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url("../../fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: white !important;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  touch-action: manipulation;
  height: 60px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(
    60deg,
    #ff7f50,
    #8b0000,
    #229ed9,
    #25d366,
    #ff7f50,
    #8b0000
  );
  animation: animatedgradient 3s ease infinite alternate;
  background-size: 300% 300%;
}

.button-85:hover {
  transform: scale(0.94);
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg, #229ed9, #25d366, #ff7f50, #8b0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.bordered {
  border-radius: 20px;
  margin: 0 5px;
  div:first-child {
    border: 1.5px solid rgb(249, 174, 0);
    border-radius: 23px;
  }
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    60deg,
    #ff7f50,
    #8b0000,
    #229ed9,
    #25d366,
    #ff7f50,
    #8b0000
  );
  animation: glowing-button-85 20s linear infinite;
  background-size: 300% 300%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.video-margin {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.item-center {
  display: flex;
  justify-content: center;
}

.button-85-mobile {
  position: fixed;
  bottom: 0;
  border-radius: 0;
}

.button-85-mobile:after {
  border-radius: 0;
}

.button-85-mobile:before {
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .button-85 {
    font-size: 16px;
  }

  .content-tabs {
    .ant-tabs-nav-list .ant-tabs-tab-active,
    .ant-tabs-nav-list .ant-tabs-tab {
      width: 160px !important;
    }
  }
}
